import { createApp } from '@o-din/app';

import Maintenance from '@o-din-maintenance/src/o-din-maintenance';
import Ppr from '@o-din-ppr/src/o-din-ppr';
import Audit from '@o-din-audit/src/o-din-audit';
import Rounds from '@o-din-rounds/src/o-din-rounds';
import Parking from '@o-din-parking/src/o-din-parking';
import Report from '@o-din-report/src/o-din-report';

createApp('#app', [Audit, Maintenance, Rounds, Report, Parking, Ppr]);
